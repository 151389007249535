// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Cx from "rescript-classnames/src/Cx.res.js";
import * as H3 from "../../../styleguide/components/Heading/H3.res.js";
import * as IconOk from "../../../styleguide/icons/IconOk.res.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as ProductSpecsScss from "./ProductSpecs.scss";

var css = ProductSpecsScss;

function ProductSpecs(props) {
  var $$location = props.location;
  return JsxRuntime.jsxs("div", {
              children: [
                JsxRuntime.jsx(H3.make, {
                      children: "Data Center Specifications"
                    }),
                JsxRuntime.jsxs("div", {
                      children: [
                        JsxRuntime.jsxs("div", {
                              children: [
                                JsxRuntime.jsxs("div", {
                                      children: [
                                        JsxRuntime.jsx("span", {
                                              children: "Building Size: ",
                                              className: css.spec
                                            }),
                                        JsxRuntime.jsx("span", {
                                              children: $$location.grossBuildingSize + " sqft",
                                              className: css.value
                                            })
                                      ],
                                      className: css.tableCell
                                    }),
                                JsxRuntime.jsxs("div", {
                                      children: [
                                        JsxRuntime.jsx("span", {
                                              children: "Colocation Space: ",
                                              className: css.spec
                                            }),
                                        JsxRuntime.jsx("span", {
                                              children: $$location.grossColocationSpace + " sqft",
                                              className: css.value
                                            })
                                      ],
                                      className: css.tableCell
                                    })
                              ],
                              className: Cx.cx([
                                    css.tableRow,
                                    css.tableRowDark
                                  ])
                            }),
                        JsxRuntime.jsxs("div", {
                              children: [
                                JsxRuntime.jsxs("div", {
                                      children: [
                                        JsxRuntime.jsx("span", {
                                              children: "Total Power: ",
                                              className: css.spec
                                            }),
                                        JsxRuntime.jsx("span", {
                                              children: $$location.totalPowerMw + " kVa",
                                              className: css.value
                                            })
                                      ],
                                      className: css.tableCell
                                    }),
                                JsxRuntime.jsxs("div", {
                                      children: [
                                        JsxRuntime.jsx("span", {
                                              children: "Power Density: ",
                                              className: css.spec
                                            }),
                                        JsxRuntime.jsx("span", {
                                              children: $$location.powerDensity,
                                              className: css.value
                                            })
                                      ],
                                      className: css.tableCell
                                    })
                              ],
                              className: css.tableRow
                            })
                      ],
                      className: css.tableContainer
                    }),
                $$location.certifications.length >= 1 ? JsxRuntime.jsx(H3.make, {
                        className: css.headingMargin,
                        children: "Data Center Certifications"
                      }) : null,
                JsxRuntime.jsx("div", {
                      children: Belt_Array.mapWithIndex($$location.certifications, (function (index, cert) {
                              if (index % 2 === 0) {
                                return JsxRuntime.jsxs("div", {
                                            children: [
                                              JsxRuntime.jsxs("div", {
                                                    children: [
                                                      JsxRuntime.jsxs("span", {
                                                            children: [
                                                              cert,
                                                              JsxRuntime.jsx("span", {
                                                                    children: ":"
                                                                  })
                                                            ],
                                                            className: css.spec
                                                          }),
                                                      JsxRuntime.jsx("span", {
                                                            children: JsxRuntime.jsx(IconOk.make, {
                                                                  size: "XS",
                                                                  color: "Teal"
                                                                }),
                                                            className: css.value
                                                          })
                                                    ],
                                                    className: css.tableCell
                                                  }),
                                              index !== ($$location.certifications.length - 1 | 0) ? JsxRuntime.jsxs("div", {
                                                      children: [
                                                        JsxRuntime.jsxs("span", {
                                                              children: [
                                                                Belt_Option.getWithDefault(Belt_Option.map(Belt_Array.get($$location.certifications, index + 1 | 0), (function (prim) {
                                                                            return prim;
                                                                          })), null),
                                                                JsxRuntime.jsx("span", {
                                                                      children: ":"
                                                                    })
                                                              ],
                                                              className: css.spec
                                                            }),
                                                        JsxRuntime.jsx("span", {
                                                              children: JsxRuntime.jsx(IconOk.make, {
                                                                    size: "XS",
                                                                    color: "Teal"
                                                                  }),
                                                              className: css.value
                                                            })
                                                      ],
                                                      className: css.tableCell
                                                    }) : null
                                            ],
                                            className: Cx.cx([
                                                  css.tableRow,
                                                  index % 4 === 0 ? css.tableRowDark : ""
                                                ])
                                          }, String(index));
                              } else {
                                return null;
                              }
                            })),
                      className: css.tableContainer
                    })
              ],
              className: css.specsContainer
            });
}

var make = ProductSpecs;

export {
  css ,
  make ,
}
/* css Not a pure module */
